import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { userActions } from "../../../../../../actions";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MessageFormat from "messageformat";
import _ from "lodash";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: "column"
  },
  indicator: {
    display: "none"
  }
}))(Tabs);

const MyTab = withStyles(theme => ({
  selected: {
    color: "black",
    background: "white",
    borderLeft: "2px solid red",
    fontWeight: 600
  },
  wrapper: {
    display: "block"
  },
  labelWrapped: {
    display: "table"
  }
}))(Tab);
/*
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}
*/
class SelectDependentPlan extends Component {
  state = {
    checked: [],
    value: 0,
    activeIndex: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      planSelected: "",
      plans: props.user.currentPlans,
      dependentPlans: props.user.premiumPlans,
      checked: [],
      planTypes: "",
      dependentPlanType: "",
      dependentList: props.user.dependencies,
      dependendentPlanSelected: props.user.planSelectedDependent || "",
      showDependentBadge: new Map(),
      planMatrix: new Map()
    };
    this.handleDependentCardClick = this.handleDependentCardClick.bind(this);
  }

  handleChange = (_, activeIndex) => this.setState({ activeIndex });

  handleDependentCardClick = (coverageDep, planDep, dependentType) => {
    var myDependentSelectMap = new Map();
    var showDependentBadge = new Map();
    if (this.props.user.showDependentBadge !== undefined) {
      showDependentBadge = new Map(this.props.user.showDependentBadge);
    }

    myDependentSelectMap = new Map();
    if (this.props.user.planSelectedDependent !== undefined) {
      myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
    }

    var planObjList = [];
    planObjList = myDependentSelectMap.get(coverageDep) || [];
    var planObj = { planDep: planDep, dependentType: dependentType };

    var index = planObjList.findIndex(x => x.dependentType === dependentType);
    if (index === -1) {
      planObjList.push(planObj);
    } else {
      planObjList.splice(index, 1, planObj);
    }

    myDependentSelectMap.set(coverageDep, planObjList);

    if (
      myDependentSelectMap.get(coverageDep).length ===
      this.state.planMatrix.get(coverageDep)
    ) {
      showDependentBadge.set(coverageDep, true);
    }

    this.setState(
      {
        ...this.state,
        dependendentPlanSelected: myDependentSelectMap,
        showDependentBadge: showDependentBadge
      },
      () => {
        this.props.selectDependentPlan(myDependentSelectMap);
        this.props.premiumCalculationDep(
          this.premiumCalculation(this.state.dependendentPlanSelected)
        );
        this.props.handleBadgeOnDependent(showDependentBadge);
        this.props.handleNextEnable(
          this.handleNextOnDependent(myDependentSelectMap, showDependentBadge)
            ? true
            : false
        );
      }
    );
  };

  premiumCalculationDependent = planSelectedDependent => {
    let sumPremium = 0;
    let planSelectedDependentArray = Array.from(planSelectedDependent);
    let coverage = planSelectedDependentArray[0];
    let planSelected = planSelectedDependentArray[1];
    this.state.dependentPlans.map(premiumPlans => {
      planSelected.map(selectedPlans => {
        if (
          selectedPlans.planDep.option &&
          selectedPlans.planDep.option === premiumPlans.option
        ) {
          let tempPremium = premiumPlans.details
            .find(plan => plan.header === coverage)
            .details.find(
              premium =>
                premium.header ===
                (selectedPlans.dependentType.full_name
                  ? "Individual"
                  : selectedPlans.dependentType)
            ).premium;
          sumPremium = sumPremium + tempPremium;
        } else if (selectedPlans.planDep === "Opt Out") {
          sumPremium = sumPremium + 0;
        }
      });
    });
    this.state.totalPremium = this.state.totalPremium + sumPremium;

    return sumPremium;
  };

  premiumCalculation = planSelectedDependent => {
    // let employeePremium = this.premiumCalculation(this.state.planSelected);
    let dependentPremium = 0;
    let sumPremium = 0;
    planSelectedDependent &&
      Array.from(planSelectedDependent).map(planSelectedDependent1 => {
        dependentPremium =
          dependentPremium +
          this.premiumCalculationDependent(planSelectedDependent1);
      });
    sumPremium = dependentPremium;
    return sumPremium;
  };

  handleBack1 = () => {
    this.props.handleBackStep();
  };

  handleToggle = value => () => {
    var newDependentList = this.state.dependentList;
    newDependentList[value].optOut = !newDependentList[value].optOut;
    this.setState(
      {
        ...this.state,
        dependentList: newDependentList
      },
      () => {
        this.props.dependentOptOut(this.state.dependentList);
      }
    );
  };

  componentWillMount() {
    this.props.handleNextEnable(this.state.dependendentPlanSelected);
    var tempPlan = this.props.user.planSelected;
    var myDependentSelectMap = new Map();
    var showDependentBadge = new Map();
    if (this.props.user.showDependentBadge !== undefined) {
      showDependentBadge = new Map(this.props.user.showDependentBadge);
    }

    myDependentSelectMap = new Map();
    if (this.props.user.planSelectedDependent !== undefined) {
      myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
    }

    this.props.handleNextEnable(this.state.dependendentPlanSelected);
    const planType = this.state.plans.filter(function(plan) {
      return plan.header == tempPlan;
    });
    const dependentPlanType = this.state.dependentPlans.filter(function(
      dependent
    ) {
      return dependent.header == tempPlan;
    });

    this.setState(
      {
        ...this.state,
        planType: planType,
        dependentPlanType: dependentPlanType,
        dependendentPlanSelected: myDependentSelectMap,
        showDependentBadge: showDependentBadge
      },
      () => {
        this.props.selectDependentPlan(myDependentSelectMap);
        this.props.premiumCalculationDep(
          this.premiumCalculation(this.state.dependendentPlanSelected)
        );
        this.props.handleBadgeOnDependent(showDependentBadge);
        this.props.handleNextEnable(
          this.handleNextOnDependent(myDependentSelectMap, showDependentBadge)
            ? true
            : false
        );
      }
    );
    // this.props.handleNextOnDependent(this.state.planMatrix);
  }

  createImage = obj => {
    var elements = [];
    for (var i = 0; i < obj.number; i++) {
      elements.push(<img key={i} src={obj.image} style={{ margin: "5px" }} />);
    }
    return elements;
  };
  componentDidMount() {
    var myDependentSelectMap = new Map();
    myDependentSelectMap = new Map();
    if (this.props.user.planSelectedDependent !== undefined) {
      myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
    }
    this.setState(
      {
        ...this.state,
        dependendentPlanSelected: myDependentSelectMap
      },
      () => {
        this.props.selectDependentPlan(myDependentSelectMap);
        this.props.premiumCalculationDep(
          this.premiumCalculation(this.state.dependendentPlanSelected)
        );
      }
    );
  }

  capitalizeFLetter = str => {
    return str.toLowerCase()[0].toUpperCase() + str.toLowerCase().slice(1);
  };
  formatContent = (string, argList) => {
    argList.forEach(arg => {
      string = string.replace(arg[0], arg[1]);
    });
    return string;
  };

  premiumInfo = (coverage, plan, dependent) => {
    if (dependent === "Dependants") return 0.0;
    if (dependent.full_name)
      return this.state.dependentPlans
        .find(planObj => planObj.option === plan.option)
        .details.find(detail => detail.header === coverage)
        .details.find(detail1 => detail1.header === "Individual").premium;

    // if(dependent === 'Spouse & Child(ren)')
    //   return this.state.dependentPlans.find(planObj => planObj.option === plan.option).details.find(detail => detail.header === coverage).details.find(detail1 => detail1.header === "Spouse & Child(ren)").premium;

    return this.state.dependentPlans
      .find(planObj => planObj.option === plan.option)
      .details.find(detail => detail.header === coverage)
      .details.find(detail1 => detail1.header === dependent).premium;
  };

  removeDups = names => {
    let unique = {};
    names.forEach(function(i) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  };

  capitalizeFLetter = str => {
    return str.toLowerCase()[0].toUpperCase() + str.toLowerCase().slice(1);
  };

  handleBadgeOnDependent = (planHead, countDep) => {
    let planMatrix = new Map();
    if (this.state.planMatrix !== undefined) {
      planMatrix = new Map(this.state.planMatrix);
    }
    if (countDep && planMatrix.get(planHead.planType) != countDep) {
      planMatrix.set(planHead.planType, countDep);
    }
    this.state.planMatrix = planMatrix;
  };

  handleNextOnDependent = (myDependentSelectMap, showDependentBadge) => {
    if (
      this.state.plans.length === showDependentBadge.size &&
      myDependentSelectMap.size === showDependentBadge.size
    ) {
      let tempVal = true;
      showDependentBadge.forEach((value, key, map) => {
        if (!value) return false;
      });
      return tempVal;
    } else {
      return false;
    }
  };

  render() {
    const { labels } = this.props.user.content;
    const { content, placeholderMap } = this.props.user;
    const mf = new MessageFormat("en");
    const enrolmentYear = mf.compile(labels["step3.header"]);
    const enrolmentStartEnd = mf.compile(labels["step3.description"]);
    const note = mf.compile(labels["step3.note"]);
    const placeholderMapTemp = new Map(placeholderMap);
    const { depRule } = this.props.user;
    const { classes } = this.props;

    const { planSelected } = this.props.user;
    const { activeIndex, dependentList, plans } = this.state;
    let dependentListByRelationTemp = [];

    this.props.user.dependencies.filter(dependent => {
      if (dependent.dependent === "SPOUSE") {
        dependentListByRelationTemp.push("Spouse Only");
      } else if (dependent.dependent === "CHILD") {
        dependentListByRelationTemp.push("Child(ren) Only");
      }
    });

    const dependentListByRelation = this.removeDups(
      dependentListByRelationTemp
    );

    return (
      <div className={classes.rootInfo} ref={this.myRef}>
        <div className={classes.contentInfo}>
          <div className={classes.headerInfo} style={{ padding: "0px 20px" }}>
            <Typography
              component={"span"}
              className={classes.headerText}
              dangerouslySetInnerHTML={{
                __html: enrolmentYear({
                  year: placeholderMapTemp.get("{year}")
                })
              }}
            >
              {/* {this.formatContent(labels['step3.header'],[["${year}",`${moment(this.props.user.policy_start_date).format("YYYY")}`]])} */}
              {/* Select your dependents' plan for {moment(this.props.user.policy_start_date).format("YYYY")} */}
            </Typography>
          </div>

          <div className={classes.headerInfo} style={{ padding: "0px" }}>
            <Grid container>
              <Grid item xs={2} sm={2} md={2}>
                <AppBar position="static" color="secondary">
                  <VerticalTabs
                    value={activeIndex}
                    onChange={this.handleChange}
                    fullWidth
                  >
                    {this.state.plans.map((planHead, keyplanHead1) => {
                      return (
                        <MyTab
                          icon={
                            this.props.user.showDependentBadge &&
                            this.props.user.showDependentBadge.size > 0 &&
                            this.props.user.showDependentBadge.get(
                              planHead.planType
                            ) ? (
                              <CheckCircleIcon color="primary" />
                            ) : (
                              ""
                            )
                          }
                          label={planHead.planType}
                          key={keyplanHead1}
                          style={{ height: "100px" }}
                        />
                      );
                    })}
                  </VerticalTabs>
                </AppBar>
              </Grid>
              <Grid item xs={10} sm={10} md={10}>
                {this.state.plans.map((planHead, keyplanHead) => {
                  let countDep = 0;
                  return (
                    activeIndex === keyplanHead && (
                      <TabContainer key={keyplanHead}>
                        <Paper
                          className={classes.expanded}
                          elevation={1}
                          style={{ background: "white", margin: "0px 0px" }}
                        >
                          <div
                            className={classes.panelSummary}
                            style={{ padding: "30px", border: "none" }}
                          >
                            <Typography
                              component={"span"}
                              className={classes.summaryTitle}
                              style={{
                                float: "left",
                                color: "red",
                                fontSize: "16px"
                              }}
                            >
                              <b>{planHead.planType} </b>
                            </Typography>
                          </div>

                          <div className={classes.root}>
                            <div style={{ padding: "0px 50px 50px" }}>
                              {(() => {
                                switch (
                                  depRule[planHead.coverageId].selectionMethod
                                ) {
                                  case "relation":
                                    return dependentListByRelation.map(
                                      (dependent, keyDependent) => {
                                        let disableBtn = false;
                                        var coverageSelectedOptOut = plans.filter(
                                          plan =>
                                            plan.planType === planHead.planType
                                        )[0];
                                        countDep = countDep + 1;

                                        this.handleBadgeOnDependent(
                                          planHead,
                                          countDep
                                        );
                                        return (
                                          <div
                                            style={{
                                              padding: "15px 25px 25px",
                                              background: "#f2f2f2",
                                              marginTop: "25px"
                                            }}
                                            key={keyDependent}
                                          >
                                            <Typography
                                              component={"span"}
                                              className={classes.summaryTitle}
                                              style={{
                                                float: "left",
                                                fontSize: "16px"
                                              }}
                                            >
                                              <b>{dependent} </b>
                                            </Typography>
                                            <Grid
                                              container
                                              spacing={"16px"}
                                              className={classes.gridStyle}
                                            >
                                              {Array.from(planSelected).map(
                                                (
                                                  planCovered,
                                                  kepPlanCovered
                                                ) => {
                                                  if (
                                                    planCovered[0] ===
                                                    planHead.planType
                                                  )
                                                    switch (
                                                      depRule[
                                                        planHead.coverageId
                                                      ].dependentCoverage
                                                    ) {
                                                      case "same":
                                                        var coverageSelected = plans.filter(
                                                          plan =>
                                                            plan.planType ===
                                                            planHead.planType
                                                        )[0];
                                                        var planSame = coverageSelected.planDetails.find(
                                                          element =>
                                                            element.option ===
                                                            planCovered[1]
                                                              .option
                                                        );
                                                        return (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={3}
                                                            md={3}
                                                            key={kepPlanCovered}
                                                          >
                                                            <Card
                                                              className={
                                                                classes.card
                                                              }
                                                              style={{
                                                                cursor:
                                                                  "pointer"
                                                              }}
                                                              onClick={e =>
                                                                this.handleDependentCardClick(
                                                                  coverageSelected.planType,
                                                                  planSame,
                                                                  dependent
                                                                )
                                                              }
                                                            >
                                                              <CardContent
                                                                classes={{
                                                                  root:
                                                                    classes.cardContentPad
                                                                }}
                                                              >
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  className={
                                                                    classes.cardPrice
                                                                  }
                                                                  style={
                                                                    disableBtn
                                                                      ? {
                                                                          color:
                                                                            "#C5C4C4"
                                                                        }
                                                                      : {}
                                                                  }
                                                                >
                                                                  <span
                                                                    className={
                                                                      classes.premiumText
                                                                    }
                                                                  >
                                                                    S$
                                                                    {parseFloat(
                                                                      this.premiumInfo(
                                                                        planHead.planType,
                                                                        planSame,
                                                                        dependent
                                                                      )
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </span>
                                                                  /year
                                                                </Typography>
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  className={
                                                                    classes.cardText
                                                                  }
                                                                  color="textSecondary"
                                                                >
                                                                  {
                                                                    planSame.header
                                                                  }
                                                                </Typography>
                                                              </CardContent>
                                                              <CardActions>
                                                                `$
                                                                {this.state
                                                                  .dependendentPlanSelected &&
                                                                this.state.dependendentPlanSelected.get(
                                                                  coverageSelected.planType
                                                                ) &&
                                                                this.state.dependendentPlanSelected
                                                                  .get(
                                                                    coverageSelected.planType
                                                                  )
                                                                  .filter(
                                                                    planObj =>
                                                                      planObj.dependentType ===
                                                                        dependent &&
                                                                      planObj
                                                                        .planDep
                                                                        .header ===
                                                                        planSame.header
                                                                  ).length >
                                                                  0 ? (
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    className={
                                                                      classes.buttonSelect
                                                                    }
                                                                  >
                                                                    SELECTED
                                                                  </Typography>
                                                                ) : (
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    className={
                                                                      classes.buttonNormal
                                                                    }
                                                                  >
                                                                    SELECT
                                                                  </Typography>
                                                                )}
                                                                `
                                                              </CardActions>
                                                            </Card>
                                                          </Grid>
                                                        );
                                                      case "downgrade":
                                                        var coverageSelected = plans.filter(
                                                          plan =>
                                                            plan.planType ===
                                                            planHead.planType
                                                        )[0];
                                                        var planDowngradeArr = coverageSelected.planDetails.filter(
                                                          element =>
                                                            element.option <=
                                                            planCovered[1]
                                                              .option
                                                        );

                                                        return planDowngradeArr.map(
                                                          (
                                                            planDownGrade,
                                                            planDownGradeKey
                                                          ) => {
                                                            return (
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                sm={3}
                                                                md={3}
                                                                key={
                                                                  planDownGradeKey
                                                                }
                                                              >
                                                                <Card
                                                                  className={
                                                                    classes.card
                                                                  }
                                                                  style={{
                                                                    cursor:
                                                                      "pointer"
                                                                  }}
                                                                  onClick={e =>
                                                                    this.handleDependentCardClick(
                                                                      coverageSelected.planType,
                                                                      planDownGrade,
                                                                      dependent
                                                                    )
                                                                  }
                                                                >
                                                                  <CardContent
                                                                    classes={{
                                                                      root:
                                                                        classes.cardContentPad
                                                                    }}
                                                                  >
                                                                    <Typography
                                                                      component={
                                                                        "span"
                                                                      }
                                                                      className={
                                                                        classes.cardPrice
                                                                      }
                                                                    >
                                                                      <span
                                                                        className={
                                                                          classes.premiumText
                                                                        }
                                                                      >
                                                                        S$
                                                                        {parseFloat(
                                                                          this.premiumInfo(
                                                                            planHead.planType,
                                                                            planDownGrade,
                                                                            dependent
                                                                          )
                                                                        ).toFixed(
                                                                          2
                                                                        )}
                                                                      </span>
                                                                      /year
                                                                    </Typography>
                                                                    <Typography
                                                                      component={
                                                                        "span"
                                                                      }
                                                                      className={
                                                                        classes.cardText
                                                                      }
                                                                      color="textSecondary"
                                                                    >
                                                                      {
                                                                        planDownGrade.header
                                                                      }
                                                                    </Typography>
                                                                  </CardContent>
                                                                  <CardActions>
                                                                    `$
                                                                    {this.state
                                                                      .dependendentPlanSelected &&
                                                                    this.state.dependendentPlanSelected.get(
                                                                      coverageSelected.planType
                                                                    ) &&
                                                                    this.state.dependendentPlanSelected
                                                                      .get(
                                                                        coverageSelected.planType
                                                                      )
                                                                      .filter(
                                                                        planObj =>
                                                                          planObj.dependentType ===
                                                                            dependent &&
                                                                          planObj
                                                                            .planDep
                                                                            .header ===
                                                                            planDownGrade.header
                                                                      ).length >
                                                                      0 ? (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonSelect
                                                                        }
                                                                      >
                                                                        SELECTED
                                                                      </Typography>
                                                                    ) : (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonNormal
                                                                        }
                                                                      >
                                                                        SELECT
                                                                      </Typography>
                                                                    )}
                                                                    `
                                                                  </CardActions>
                                                                </Card>
                                                              </Grid>
                                                            );
                                                          }
                                                        );
                                                    }
                                                }
                                              )}

                                              <Grid item xs={12} sm={3} md={3}>
                                                <Card
                                                  className={classes.card}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={e =>
                                                    this.handleDependentCardClick(
                                                      coverageSelectedOptOut.planType,
                                                      "Opt Out",
                                                      dependent.full_name
                                                        ? dependent.full_name
                                                        : dependent
                                                    )
                                                  }
                                                >
                                                  <CardContent
                                                    classes={{
                                                      root:
                                                        classes.cardContentPad
                                                    }}
                                                  >
                                                    <Typography
                                                      component={"span"}
                                                      className={
                                                        classes.cardPrice
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          classes.premiumText
                                                        }
                                                      >
                                                        S$0.00
                                                      </span>
                                                      /year
                                                    </Typography>
                                                    <Typography
                                                      component={"span"}
                                                      className={
                                                        classes.cardText
                                                      }
                                                      color="textSecondary"
                                                    >
                                                      Opt Out
                                                    </Typography>
                                                  </CardContent>
                                                  <CardActions>
                                                    `$
                                                    {this.state
                                                      .dependendentPlanSelected &&
                                                    this.state.dependendentPlanSelected.get(
                                                      coverageSelectedOptOut.planType
                                                    ) &&
                                                    this.state.dependendentPlanSelected
                                                      .get(
                                                        coverageSelectedOptOut.planType
                                                      )
                                                      .filter(
                                                        planObj =>
                                                          planObj.dependentType ===
                                                            (dependent.full_name
                                                              ? dependent.full_name
                                                              : dependent) &&
                                                          planObj.planDep ===
                                                            "Opt Out"
                                                      ).length > 0 ? (
                                                      <Typography
                                                        component={"span"}
                                                        className={
                                                          classes.buttonSelect
                                                        }
                                                      >
                                                        SELECTED
                                                      </Typography>
                                                    ) : (
                                                      <Typography
                                                        component={"span"}
                                                        className={
                                                          classes.buttonNormal
                                                        }
                                                      >
                                                        SELECT
                                                      </Typography>
                                                    )}
                                                    ` \{" "}
                                                  </CardActions>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        );
                                      }
                                    );
                                  case "name":
                                    return dependentList.map(
                                      (dependent, keyName) => {
                                        let disableBtn = false;
                                        var coverageSelectedOptOut = plans.filter(
                                          plan =>
                                            plan.planType === planHead.planType
                                        )[0];
                                        countDep = countDep + 1;
                                        this.handleBadgeOnDependent(
                                          planHead,
                                          countDep
                                        );
                                        return (
                                          <div
                                            style={{
                                              padding: "15px 25px 25px",
                                              background: "#f2f2f2",
                                              marginTop: "25px"
                                            }}
                                            key={keyName}
                                          >
                                            <Typography
                                              component={"span"}
                                              className={classes.summaryTitle}
                                              style={{
                                                float: "left",
                                                fontSize: "16px"
                                              }}
                                            >
                                              <b>
                                                {dependent.full_name
                                                  ? dependent.full_name
                                                  : dependent}{" "}
                                                (
                                                {this.capitalizeFLetter(
                                                  dependent.dependent
                                                )}
                                                ){" "}
                                              </b>
                                            </Typography>
                                            <Grid
                                              container
                                              spacing={"16px"}
                                              className={classes.gridStyle}
                                            >
                                              {Array.from(planSelected).map(
                                                (
                                                  planCovered,
                                                  kepPlanCovered
                                                ) => {
                                                  if (
                                                    planCovered[0] ===
                                                    planHead.planType
                                                  )
                                                    switch (
                                                      depRule[
                                                        planHead.coverageId
                                                      ].dependentCoverage
                                                    ) {
                                                      case "same":
                                                        var coverageSelected = plans.filter(
                                                          plan =>
                                                            plan.planType ===
                                                            planHead.planType
                                                        )[0];
                                                        var planSame = coverageSelected.planDetails.find(
                                                          element =>
                                                            element.option ===
                                                            planCovered[1]
                                                              .option
                                                        );
                                                        return (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={3}
                                                            md={3}
                                                            key={kepPlanCovered}
                                                          >
                                                            <Card
                                                              className={
                                                                classes.card
                                                              }
                                                              style={{
                                                                cursor:
                                                                  "pointer"
                                                              }}
                                                              onClick={e =>
                                                                this.handleDependentCardClick(
                                                                  coverageSelected.planType,
                                                                  planSame,
                                                                  dependent
                                                                )
                                                              }
                                                            >
                                                              <CardContent
                                                                classes={{
                                                                  root:
                                                                    classes.cardContentPad
                                                                }}
                                                              >
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  className={
                                                                    classes.cardPrice
                                                                  }
                                                                  style={
                                                                    disableBtn
                                                                      ? {
                                                                          color:
                                                                            "#C5C4C4"
                                                                        }
                                                                      : {}
                                                                  }
                                                                >
                                                                  <span
                                                                    className={
                                                                      classes.premiumText
                                                                    }
                                                                  >
                                                                    S$
                                                                    {parseFloat(
                                                                      this.premiumInfo(
                                                                        planHead.planType,
                                                                        planSame,
                                                                        dependent
                                                                      )
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </span>
                                                                  /year
                                                                </Typography>
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  className={
                                                                    classes.cardText
                                                                  }
                                                                  color="textSecondary"
                                                                >
                                                                  {
                                                                    planSame.header
                                                                  }
                                                                </Typography>
                                                              </CardContent>
                                                              <CardActions>
                                                                `$
                                                                {this.state
                                                                  .dependendentPlanSelected &&
                                                                this.state.dependendentPlanSelected.get(
                                                                  coverageSelected.planType
                                                                ) &&
                                                                this.state.dependendentPlanSelected
                                                                  .get(
                                                                    coverageSelected.planType
                                                                  )
                                                                  .filter(
                                                                    planObj =>
                                                                      planObj
                                                                        .dependentType
                                                                        .full_name ===
                                                                        dependent.full_name &&
                                                                      planObj
                                                                        .planDep
                                                                        .header ===
                                                                        planSame.header
                                                                  ).length >
                                                                  0 ? (
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    className={
                                                                      classes.buttonSelect
                                                                    }
                                                                  >
                                                                    SELECTED
                                                                  </Typography>
                                                                ) : (
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    className={
                                                                      classes.buttonNormal
                                                                    }
                                                                  >
                                                                    SELECT
                                                                  </Typography>
                                                                )}
                                                                `
                                                              </CardActions>
                                                            </Card>
                                                          </Grid>
                                                        );

                                                      case "downgrade":
                                                        var coverageSelected = plans.filter(
                                                          plan =>
                                                            plan.planType ===
                                                            planHead.planType
                                                        )[0];
                                                        var planDowngradeArr = coverageSelected.planDetails.filter(
                                                          element =>
                                                            element.option <=
                                                            planCovered[1]
                                                              .option
                                                        );
                                                        return planDowngradeArr.map(
                                                          (
                                                            planDownGrade,
                                                            planDownGradeKey
                                                          ) => {
                                                            return (
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                sm={3}
                                                                md={3}
                                                                key={
                                                                  planDownGradeKey
                                                                }
                                                              >
                                                                <Card
                                                                  className={
                                                                    classes.card
                                                                  }
                                                                  style={{
                                                                    cursor:
                                                                      "pointer"
                                                                  }}
                                                                  onClick={e =>
                                                                    this.handleDependentCardClick(
                                                                      coverageSelected.planType,
                                                                      planDownGrade,
                                                                      dependent
                                                                    )
                                                                  }
                                                                >
                                                                  <CardContent
                                                                    classes={{
                                                                      root:
                                                                        classes.cardContentPad
                                                                    }}
                                                                  >
                                                                    <Typography
                                                                      component={
                                                                        "span"
                                                                      }
                                                                      className={
                                                                        classes.cardPrice
                                                                      }
                                                                    >
                                                                      <span
                                                                        className={
                                                                          classes.premiumText
                                                                        }
                                                                      >
                                                                        S$
                                                                        {parseFloat(
                                                                          this.premiumInfo(
                                                                            planHead.planType,
                                                                            planDownGrade,
                                                                            dependent
                                                                          )
                                                                        ).toFixed(
                                                                          2
                                                                        )}
                                                                      </span>
                                                                      /year
                                                                    </Typography>
                                                                    <Typography
                                                                      component={
                                                                        "span"
                                                                      }
                                                                      className={
                                                                        classes.cardText
                                                                      }
                                                                      color="textSecondary"
                                                                    >
                                                                      {
                                                                        planDownGrade.header
                                                                      }
                                                                    </Typography>
                                                                  </CardContent>
                                                                  <CardActions>
                                                                    `$
                                                                    {this.state
                                                                      .dependendentPlanSelected &&
                                                                    this.state.dependendentPlanSelected.get(
                                                                      coverageSelected.planType
                                                                    ) &&
                                                                    this.state.dependendentPlanSelected
                                                                      .get(
                                                                        coverageSelected.planType
                                                                      )
                                                                      .filter(
                                                                        planObj =>
                                                                          planObj
                                                                            .dependentType
                                                                            .full_name ===
                                                                            dependent.full_name &&
                                                                          planObj
                                                                            .planDep
                                                                            .header ===
                                                                            planDownGrade.header
                                                                      ).length >
                                                                      0 ? (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonSelect
                                                                        }
                                                                      >
                                                                        SELECTED
                                                                      </Typography>
                                                                    ) : (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonNormal
                                                                        }
                                                                      >
                                                                        SELECT
                                                                      </Typography>
                                                                    )}
                                                                    `
                                                                  </CardActions>
                                                                </Card>
                                                              </Grid>
                                                            );
                                                          }
                                                        );
                                                    }
                                                }
                                              )}
                                              <Grid item xs={12} sm={3} md={3}>
                                                <Card
                                                  className={classes.card}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={e =>
                                                    this.handleDependentCardClick(
                                                      coverageSelectedOptOut.planType,
                                                      "Opt Out",
                                                      dependent
                                                    )
                                                  }
                                                >
                                                  <CardContent
                                                    classes={{
                                                      root:
                                                        classes.cardContentPad
                                                    }}
                                                  >
                                                    <Typography
                                                      component={"span"}
                                                      className={
                                                        classes.cardPrice
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          classes.premiumText
                                                        }
                                                      >
                                                        S$0.00
                                                      </span>
                                                      /year
                                                    </Typography>
                                                    <Typography
                                                      component={"span"}
                                                      className={
                                                        classes.cardText
                                                      }
                                                      color="textSecondary"
                                                    >
                                                      Opt Out
                                                    </Typography>
                                                  </CardContent>
                                                  <CardActions>
                                                    `$
                                                    {this.state
                                                      .dependendentPlanSelected &&
                                                    this.state.dependendentPlanSelected.get(
                                                      coverageSelectedOptOut.planType
                                                    ) &&
                                                    this.state.dependendentPlanSelected
                                                      .get(
                                                        coverageSelectedOptOut.planType
                                                      )
                                                      .filter(
                                                        planObj =>
                                                          planObj.dependentType
                                                            .full_name ===
                                                            dependent.full_name &&
                                                          planObj.planDep ===
                                                            "Opt Out"
                                                      ).length > 0 ? (
                                                      <Typography
                                                        component={"span"}
                                                        className={
                                                          classes.buttonSelect
                                                        }
                                                      >
                                                        SELECTED
                                                      </Typography>
                                                    ) : (
                                                      <Typography
                                                        component={"span"}
                                                        className={
                                                          classes.buttonNormal
                                                        }
                                                      >
                                                        SELECT
                                                      </Typography>
                                                    )}
                                                    `
                                                  </CardActions>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        );
                                      }
                                    );
                                  case "family": {
                                    let disableBtn = false;
                                    var coverageSelectedOptOut = plans.filter(
                                      plan =>
                                        plan.planType === planHead.planType
                                    )[0];
                                    countDep = countDep + 1;
                                    this.handleBadgeOnDependent(
                                      planHead,
                                      countDep
                                    );
                                    return (
                                      <div
                                        style={{
                                          padding: "15px 25px 25px",
                                          background: "#f2f2f2",
                                          marginTop: "25px"
                                        }}
                                      >
                                        <Typography
                                          component={"span"}
                                          className={classes.summaryTitle}
                                          style={{
                                            float: "left",
                                            fontSize: "16px"
                                          }}
                                        >
                                          <b>Spouse & Child(ren)</b>
                                        </Typography>
                                        <Grid
                                          container
                                          spacing={"16px"}
                                          className={classes.gridStyle}
                                        >
                                          {Array.from(planSelected).map(
                                            (planCovered, kepPlanCovered) => {
                                              if (
                                                planCovered[0] ===
                                                planHead.planType
                                              )
                                                switch (
                                                  depRule[planHead.coverageId]
                                                    .dependentCoverage
                                                ) {
                                                  case "same":
                                                    var coverageSelected = plans.filter(
                                                      plan =>
                                                        plan.planType ===
                                                        planHead.planType
                                                    )[0];
                                                    var planSame = coverageSelected.planDetails.find(
                                                      element =>
                                                        element.option ===
                                                        planCovered[1].option
                                                    );
                                                    var dependentFamily =
                                                      "Spouse & Child(ren)";
                                                    return (
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        sm={3}
                                                        md={3}
                                                        key={kepPlanCovered}
                                                      >
                                                        <Card
                                                          className={
                                                            classes.card
                                                          }
                                                          style={{
                                                            cursor: "pointer"
                                                          }}
                                                          onClick={e =>
                                                            this.handleDependentCardClick(
                                                              coverageSelected.planType,
                                                              planSame,
                                                              dependentFamily
                                                            )
                                                          }
                                                        >
                                                          <CardContent
                                                            classes={{
                                                              root:
                                                                classes.cardContentPad
                                                            }}
                                                          >
                                                            <Typography
                                                              component={"span"}
                                                              className={
                                                                classes.cardPrice
                                                              }
                                                            >
                                                              <span
                                                                className={
                                                                  classes.premiumText
                                                                }
                                                              >
                                                                S$
                                                                {parseFloat(
                                                                  this.premiumInfo(
                                                                    planHead.planType,
                                                                    planSame,
                                                                    dependentFamily
                                                                  )
                                                                ).toFixed(2)}
                                                              </span>
                                                              /year
                                                            </Typography>
                                                            <Typography
                                                              component={"span"}
                                                              className={
                                                                classes.cardText
                                                              }
                                                              color="textSecondary"
                                                            >
                                                              {planSame.header}
                                                            </Typography>
                                                          </CardContent>
                                                          <CardActions>
                                                            `$
                                                            {this.state
                                                              .dependendentPlanSelected &&
                                                            this.state.dependendentPlanSelected.get(
                                                              coverageSelected.planType
                                                            ) &&
                                                            this.state.dependendentPlanSelected
                                                              .get(
                                                                coverageSelected.planType
                                                              )
                                                              .filter(
                                                                planObj =>
                                                                  planObj.dependentType ===
                                                                    "Spouse & Child(ren)" &&
                                                                  planObj
                                                                    .planDep
                                                                    .header ===
                                                                    planSame.header
                                                              ).length > 0 ? (
                                                              <Typography
                                                                component={
                                                                  "span"
                                                                }
                                                                className={
                                                                  classes.buttonSelect
                                                                }
                                                              >
                                                                SELECTED
                                                              </Typography>
                                                            ) : (
                                                              <Typography
                                                                component={
                                                                  "span"
                                                                }
                                                                className={
                                                                  classes.buttonNormal
                                                                }
                                                              >
                                                                SELECT
                                                              </Typography>
                                                            )}
                                                            `
                                                          </CardActions>
                                                        </Card>
                                                      </Grid>
                                                    );
                                                  case "downgrade":
                                                    var coverageSelected = plans.filter(
                                                      plan =>
                                                        plan.planType ===
                                                        planHead.planType
                                                    )[0];

                                                    var planDowngradeArr = coverageSelected.planDetails.filter(
                                                      element =>
                                                        element.option <=
                                                        planCovered[1].option
                                                    );
                                                    return planDowngradeArr.map(
                                                      (
                                                        planDownGrade,
                                                        planDownGradeKey
                                                      ) => {
                                                        return (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={3}
                                                            md={3}
                                                            key={
                                                              planDownGradeKey
                                                            }
                                                          >
                                                            <Card
                                                              className={
                                                                classes.card
                                                              }
                                                              style={{
                                                                cursor:
                                                                  "pointer"
                                                              }}
                                                              onClick={e =>
                                                                this.handleDependentCardClick(
                                                                  coverageSelected.planType,
                                                                  planDownGrade,
                                                                  dependent
                                                                )
                                                              }
                                                            >
                                                              <CardContent
                                                                classes={{
                                                                  root:
                                                                    classes.cardContentPad
                                                                }}
                                                              >
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  className={
                                                                    classes.cardPrice
                                                                  }
                                                                >
                                                                  <span
                                                                    className={
                                                                      classes.premiumText
                                                                    }
                                                                  >
                                                                    S$
                                                                    {parseFloat(
                                                                      this.premiumInfo(
                                                                        planHead.planType,
                                                                        planDownGrade,
                                                                        dependent
                                                                      )
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </span>
                                                                  /year
                                                                </Typography>
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  className={
                                                                    classes.cardText
                                                                  }
                                                                  color="textSecondary"
                                                                >
                                                                  {
                                                                    planDownGrade.header
                                                                  }
                                                                </Typography>
                                                              </CardContent>
                                                              <CardActions>
                                                                `$
                                                                {this.state
                                                                  .dependendentPlanSelected &&
                                                                this.state.dependendentPlanSelected.get(
                                                                  coverageSelected.planType
                                                                ) &&
                                                                this.state.dependendentPlanSelected
                                                                  .get(
                                                                    coverageSelected.planType
                                                                  )
                                                                  .filter(
                                                                    planObj =>
                                                                      planObj.dependentType ===
                                                                        dependent &&
                                                                      planObj
                                                                        .planDep
                                                                        .header ===
                                                                        planDownGrade.header
                                                                  ).length >
                                                                  0 ? (
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    className={
                                                                      classes.buttonSelect
                                                                    }
                                                                  >
                                                                    SELECTED
                                                                  </Typography>
                                                                ) : (
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    className={
                                                                      classes.buttonNormal
                                                                    }
                                                                  >
                                                                    SELECT
                                                                  </Typography>
                                                                )}
                                                                `
                                                              </CardActions>
                                                            </Card>
                                                          </Grid>
                                                        );
                                                      }
                                                    );
                                                }
                                            }
                                          )}
                                          <Grid item xs={12} sm={3} md={3}>
                                            <Card
                                              className={classes.card}
                                              style={{ cursor: "pointer" }}
                                              onClick={e =>
                                                this.handleDependentCardClick(
                                                  coverageSelectedOptOut.planType,
                                                  "Opt Out",
                                                  "Spouse & Child(ren)"
                                                )
                                              }
                                            >
                                              <CardContent
                                                classes={{
                                                  root: classes.cardContentPad
                                                }}
                                              >
                                                <Typography
                                                  component={"span"}
                                                  className={classes.cardPrice}
                                                >
                                                  <span
                                                    className={
                                                      classes.premiumText
                                                    }
                                                  >
                                                    S$0.00
                                                  </span>
                                                  /year
                                                </Typography>
                                                <Typography
                                                  component={"span"}
                                                  className={classes.cardText}
                                                  color="textSecondary"
                                                >
                                                  Opt Out
                                                </Typography>
                                              </CardContent>
                                              <CardActions>
                                                `$
                                                {this.state
                                                  .dependendentPlanSelected &&
                                                this.state.dependendentPlanSelected.get(
                                                  coverageSelectedOptOut.planType
                                                ) &&
                                                this.state.dependendentPlanSelected
                                                  .get(
                                                    coverageSelectedOptOut.planType
                                                  )
                                                  .filter(
                                                    planObj =>
                                                      planObj.dependentType ===
                                                        "Spouse & Child(ren)" &&
                                                      planObj.planDep ===
                                                        "Opt Out"
                                                  ).length > 0 ? (
                                                  <Typography
                                                    component={"span"}
                                                    className={
                                                      classes.buttonSelect
                                                    }
                                                  >
                                                    SELECTED
                                                  </Typography>
                                                ) : (
                                                  <Typography
                                                    component={"span"}
                                                    className={
                                                      classes.buttonNormal
                                                    }
                                                  >
                                                    SELECT
                                                  </Typography>
                                                )}
                                                `
                                              </CardActions>
                                            </Card>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    );
                                  }
                                  default:
                                    return "";
                                }
                              })()}
                            </div>
                          </div>
                        </Paper>
                      </TabContainer>
                    )
                  );
                })}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  rootInfo: {
    height: "100%",
    padding: "25px"
  },
  rootList: {
    width: "100%"
  },
  headerText: {
    color: "#ED1C24",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    padding: "10px 0px"
  },
  subHeaderText: {
    color: "#000000",
    fontSize: "18px",
    lineHeight: "30px"
  },
  subHeader1Text: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "23px",
    display: "flex",
    alignItems: "center"
  },
  subHeader2Text: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center"
  },
  cardText: {
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#68737A"
  },

  contentInfo: {
    paddingTop: 10,
    background: "#FFFFFF",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  headerInfo: {
    padding: theme.spacing.unit * 2.5
  },
  content: {
    color: "#6B6A6D",
    padding: "5px",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card: {
    height: "100%",
    background: "#FFFFFF",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    borderRadius: "4px"
  },
  cardSelected: {
    height: "100%",
    background: "#FFFFFF",
    border: "3px solid #ED1C24",
    boxSizing: "border-box",
    borderRadius: "4px"
  },

  premiumText: {
    color: "#333333",
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "40px",
    alignItems: "center"
  },

  cardPrice: {
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "40px",
    color: "#000000",
    display: "block",
    wordBreak: "break-all"
  },

  cardAction: {
    display: "block",
    width: "100%",
    height: "100%",
    textAlign: "initial"
  },

  buttonSelect: {
    fontSize: "15.75px",
    lineHeight: "40px",
    textAlign: "center",
    color: "#FFF",
    background: "#ED1C24",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    borderRadius: "100px",
    width: "100%"
  },
  buttonNormal: {
    fontSize: "15.75px",
    lineHeight: "40px",
    textAlign: "center",
    color: "#333333",
    background: "#FFFFFF",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    borderRadius: "100px",
    width: "100%"
  },
  buttonDisable: {
    fontSize: "15.75px",
    lineHeight: "40px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.26)",
    background: "rgba(0, 0, 0, 0.12)",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    boxShadow: "none",
    borderRadius: "100px",
    width: "100%"
  },
  note: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#6B6A6D",
    padding: "15px"
  },
  bottomNote: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#6B6A6D"
  },
  bottomBackgroud: {
    background: "#F6F6F6",
    borderRadius: "0px 0px 4px 4px"
  },
  topBackgroud: {
    background: "#F6F6F6",
    padding: "20px",
    borderRadius: "0px 0px 4px 4px"
  },
  listItem: {
    padding: "20px 0px",
    borderBottom: "1px solid #C5C4C4"
  },
  listItemCol: {
    width: "40%",
    fontSize: "18px",
    lineHeight: "40px",
    color: "#000000"
  },
  listItemCheck: {
    width: "20%"
  },
  socialMedia: {
    width: 60,
    height: 60,
    marginLeft: 20,
    marginTop: 5
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  cardContentPad: {
    padding: 0
  }
});

// const theme = createMuiTheme({
//   overrides: {
//     // Style sheet name ⚛️
//     MuiCardContent: {
//       // Name of the rule
//       root: {
//         // Some CSS
//         padding: '0px',
//       },
//     },
//   },
// });

function mapStateToProps(state) {
  return {
    user: state.user,
    planSelectedDependent: state.user.planSelectedDependent,
    enableNext: state.user.enableNext,
    state: state
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(SelectDependentPlan)));
