import React, { Component } from "react";

import classNames from "classnames";

import { withStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { images as assetsImages } from "../assets";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    alert("Logout handling");
  };

  isLandscapeMode = () => {
    const isTablet = /iPad|Android|Tablet|PlayBook|Silk/i.test(navigator.userAgent);
    const isLandscapeMode =  window.innerWidth > window.innerHeight;
    return isTablet || isLandscapeMode;
  };

  render() {
    const { classes, drawerOpen, isMobile } = this.props;
    let images = assetsImages;

    return (
      <AppBar
        className={classNames(
          classes.appBar,
          isMobile ? drawerOpen && classes.appBarShift : drawerOpen
        )}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo}>
            <IconButton
              aria-label="open drawer"
              onClick={this.props.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                drawerOpen && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            {this.isLandscapeMode() && (
              <img
                src={images.pru_menu_work_logo}
                alt="logo"
                className={classes.pruworkImgLogo}
              />
            )}
          </div>
          <div className={classes.brandLogoContainer}>
            <img
              src={images.pru_menu_logo}
              className={classes.pruLogo}
              alt="logo"
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}
const styles = theme => {
  return {
    flex: {
      flex: 1
    },
    logo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between"
    },
    imgLogo: {
      maxWidth: "50%"
    },
    appBar: {
      position: "absolute",
      backgroundColor: "white",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: "100%",
      width: "100%",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    brandLogoContainer: {
      alignItems: "flex-end",
      textAlign: "right"
    },
    pruLogo: {
      maxWidth: 157
    },
    pruworkImgLogo: {
      maxWidth: 177
    }
  };
};

export default withStyles(styles, { withTheme: true })(Header);
