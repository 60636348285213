import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import SimpleMessageDialog from "../../components/SimpleMessageDialog";
import SimpleControlDialog from "../../components/SimpleControlDialog";
import { Form, Field } from "react-final-form";
import { renderTextField } from "../../components/CustomReduxFormComponent";

const required = value => (value ? undefined : "Required");

class ForgotPassworDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      success: false,
      errorMessage: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.formApi?.reset()
      this.setState({ success: false, errorMessage: null });
    }
  }

  handleCloseSuccess() {
    this.setState({ success: [] }, () => {
      this.props.closeHandler();
    });
  }

  submit = values => {
    this.props.forgotPassword(values.email.trim().toLowerCase()).then(
      resp => {
        console.log("debug resp", resp, values);
        if (resp.success)
          this.setState({ success: true, email: values.email });
        else this.setState({ errorMessage: resp.message });
      },
      error => {
        this.setState({ errorMessage: error.customMessage || error.message });
      }
    );
  };

  handleClose() {
    this.setState({ success: false });
    this.props.closeHandler();
  }

  render() {
    const { success, errorMessage } = this.state;
    const { classes, show } = this.props;
    return (
      <div>
        {!success && (
          <>
            <Form 
              onSubmit={this.submit}
              render={({ handleSubmit, valid, form }) => {
                this.formApi = form;
                return (
                  <SimpleControlDialog
                    show={show}
                    title="Forgot Your Password?"
                    description="Enter your email address to receive a password reset email"
                    onClose={this.props.closeHandler}
                    closeHandler={this.handleClose.bind(this)}
                    valid={valid}
                    onSubmit={handleSubmit}
                    okButtonText="Submit"
                    closeButtonText="Cancel"
                  >
                    <div className={classes.resetPasswordForm}>
                      <Field
                        name="email"
                        autoFocus
                        margin="dense"
                        component={renderTextField}
                        autoComplete="[off]"
                        label="Email address"
                        type="text"
                        validate={required}
                      />
                    </div>
                    <p className={classes.errorMessage}>{errorMessage}</p>
                  </SimpleControlDialog>
                )
              }}
            />
          </>
        )}
        <SimpleMessageDialog
          type="success"
          name="reset-password-successfully"
          title="Forgot Your Password?"
          description={`We've sent a password reset link to your email. If you didn't receive it, please contact your HR or call PruCustomer Line at 1800 835 9733 or +65 6233 3299 (for overseas).`}
          closeButtonText="OK"
          closeHandler={this.handleCloseSuccess.bind(this)}
          open={success}
        />
      </div>
    );
  }
}

const styles = theme => ({
  successIcon: {
    fontSize: 50,
    marginBottom: 20,
    color: "green"
  },
  successContent: {
    textAlign: "center"
  },
  resetPasswordForm: {
    textAlign: "center"
  },
  errorMessage: {
    marginTop: theme.spacing.unit * 1,
    color: theme.palette.error.main,
    fontFamily: "OpenSans"
  }
});

export default withStyles(styles, { withTheme: true })(ForgotPassworDialog);
