import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import classNames from "classnames";
import { Field } from "react-final-form";
import { DatePicker } from "@mui/x-date-pickers";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  labelError: {
    color: theme.palette.error.main,
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0)
  },
  inputMargin: {
    marginTop: theme.spacing(2),
  },
  info: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0)
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    width: "100%"
  }
});

const withTextField = ({ 
  input, 
  label, 
  meta,
  isSelect, 
  children,
  handleChange,
  classes,
  helperText,
  ...custom 
}) => {
  return (
    <div>
      <TextField
        {...input}
        {...custom}
        margin="normal"
        fullWidth
        select={isSelect || false}
        error={meta.touched && meta.error !== undefined}
        label={label}
        onChange={(e) => {
          handleChange && handleChange(e);
          input.onChange(e);
        }}
      >
        {children}
      </TextField>
      <FormHelperText
        className={classNames(classes.error)}
      >
        {meta.touched && meta.error ? meta.error : helperText}
      </FormHelperText>
    </div>
  );
};

export const renderTextField = withStyles(styles, { withTheme: true })(withTextField);

const withSelectInput = ({input, meta, handleChange, options, helperText, classes, ...rest}) => {
    return (
        <>
          <TextField
          {...input}
          {...rest}
          select
          fullWidth
          variant="outlined"
          error={meta.touched && !!meta.error}
          onChange={(e) => {
              handleChange && handleChange(e);
              input.onChange(e);
          }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                  {option.label}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText
            className={classNames(classes.error)}
          >
            {meta.touched && meta.error ? meta.error : helperText}
          </FormHelperText>
        </>
    )
}

export const renderSelectInput = withStyles(styles, { withTheme: true })(withSelectInput);

const withDatePickerValidator = ({
  name,
  helperText,
  classes,
  handleDateChange,
  validate,
  ...rest
}) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        console.log("debug input", input, rest)
        return (
          <div>
            <DatePicker
              {...input}
              {...rest}
              onChange={(value) => { 
                input.onChange(value);
                handleDateChange && handleDateChange(value);
              }}
            />
            <FormHelperText
              className={classNames(classes.error)}
            >
              {meta.touched && meta.error ? meta.error : helperText}
            </FormHelperText>
          </div>
        );
      }}
    </Field>
  );
};

export const RenderDatePickerValidator = withStyles(styles, { withTheme: true })(withDatePickerValidator)

const withAutoComplete = ({ autoCompleteProps, name, helperText, classes,validate, ...rest}) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const isValid = !(meta.touched && meta.error);
        return (
          <Autocomplete
            {...autoCompleteProps}
            onChange={(event, newValue) => {
                input.onChange(newValue ? newValue.value : "");
                autoCompleteProps.onChange(event, newValue);
              }
            }
            renderInput={(params) => (
              <>
                <TextField
                  {...input}
                  {...params}
                  error={!isValid}
                  {...rest}
                />
                <FormHelperText
                  className={classNames(classes.error)}
                >
                  {meta.touched && meta.error ? meta.error : helperText}
                </FormHelperText>
              </>
            )}
          />
        );
      }}
    </Field>
  )
}

export const RenderAutoComplete = withStyles(styles, { withTheme: true })(withAutoComplete)
