import PolicyCompDisabledConfig from "./PolicyCompDisabledConfig.js";
import CodeCompDisabledConfig from "./CodeCompDisabledConfig.js";
import CiamResponseCode from "./CiamResponseCode.js";

const POSITION = [1, 1, 2, 3, 5, 8, 13, 21];

class Util {
  static validateLogin(errorCode) {
    switch (errorCode) {
      case CiamResponseCode.PRUWORKS_EN_CODE.code:
        return CiamResponseCode.PRUWORKS_EN_CODE.description;

      case CiamResponseCode.A_SUSPEND_INACTIVE.in_active_code:
      case CiamResponseCode.A_SUSPEND_INACTIVE.suspend_code:
      case CiamResponseCode.A_SUSPEND_INACTIVE.pw_expired:
        return CiamResponseCode.A_SUSPEND_INACTIVE.description;

      case CiamResponseCode.ACCOUNT_BLOCK.code:
        return CiamResponseCode.ACCOUNT_BLOCK.description;

      case CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.code:
        return CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.description_login;

      case CiamResponseCode.INCORRECT_PW.code:
      case CiamResponseCode.NOT_AUTHENTICATED:
      case CiamResponseCode.UNAUTHORIZED:
        return CiamResponseCode.GENERAL_MESSAGE;

      default:
        return CiamResponseCode.GENERAL_MESSAGE;
    }
  }
  static validateOtpLogin(errorCode) {
    switch (errorCode) {
      case CiamResponseCode.MAX_OTP_FAILED.code:
        return CiamResponseCode.MAX_OTP_FAILED.description;

      case CiamResponseCode.OTP_EXPIRED.code:
      case CiamResponseCode.OTP_EXPIRED.code_old:
        return CiamResponseCode.OTP_EXPIRED.description;

      case CiamResponseCode.RESETPW_OTP_EXPIRED.code:
        return CiamResponseCode.RESETPW_OTP_EXPIRED.description;

      case CiamResponseCode.FORGEROCK_ERROR.code:
        return CiamResponseCode.FORGEROCK_ERROR.description_otp;

      case CiamResponseCode.FORGEROCK_ERROR.codeReqOtp:
        return CiamResponseCode.FORGEROCK_ERROR.description_reqOtp;
      case CiamResponseCode.ACCOUNT_BLOCK.code:
        return CiamResponseCode.ACCOUNT_BLOCK.description;

      default:
        return CiamResponseCode.INVALID_OTP.description;
    }
  }

  static validateOtpResetPassword(error) {
    switch (error) {
      case CiamResponseCode.MAX_OTP_FAILED.code:
        return CiamResponseCode.MAX_OTP_FAILED.description_reset_password;

      case CiamResponseCode.OTP_EXPIRED.code:
      case CiamResponseCode.OTP_EXPIRED.code_old:
        return CiamResponseCode.OTP_EXPIRED.description_reset_password;

      case CiamResponseCode.RESETPW_OTP_EXPIRED.code:
        return CiamResponseCode.RESETPW_OTP_EXPIRED.description;

      case CiamResponseCode.FORGEROCK_ERROR.code:
        return CiamResponseCode.FORGEROCK_ERROR.description_reset_password;

      case CiamResponseCode.FORGEROCK_ERROR.codeReqOtp:
        return CiamResponseCode.FORGEROCK_ERROR.description_otp;
      case CiamResponseCode.ACCOUNT_BLOCK.code:
        return CiamResponseCode.ACCOUNT_BLOCK.description_reset_password;
      case CiamResponseCode.EXCEEDED_OTP_REGENERATION.code:
        return CiamResponseCode.EXCEEDED_OTP_REGENERATION
          .description_reset_password;

      case CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.code:
        return CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.description;

      default:
        return CiamResponseCode.INVALID_OTP.description;
    }
  }

  static errorCodeCloseOtpDialog(error) {
    console.log("util===============: " + error.fail);
    error.isCloseOtpDialog = true;
    switch (error.message) {
      case CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.code:
        error.customMessage =
          CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.description;
        break;
      case CiamResponseCode.ACCOUNT_LOCKED_FOR_WRONG_OTP_RESET_PWD.code:
        error.customMessage =
          CiamResponseCode.ACCOUNT_LOCKED_FOR_WRONG_OTP_RESET_PWD.description;
        break;
      case CiamResponseCode.MAX_OTP_FAILED.code:
        error.customMessage = CiamResponseCode.MAX_OTP_FAILED.description;
        break;
      case CiamResponseCode.EXCEEDED_OTP_REGENERATION.code:
        error.customMessage =
          CiamResponseCode.EXCEEDED_OTP_REGENERATION.description;
        break;
      case CiamResponseCode.FORGEROCK_ERROR.code:
        error.customMessage =
          CiamResponseCode.FORGEROCK_ERROR.description_reqOtp;
        break;
      default:
        error.isCloseOtpDialog = false;
        break;
    }
  }

  static isEmpty(obj) {
    if (typeof obj === "undefined" || obj === null) return true;
    if (typeof obj === "object") {
      if (Array.isArray(obj)) {
        return obj.length === 0;
      } else {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) return false;
        }
        return true;
      }
    }
    if (typeof obj === "string" && obj === "") return true;
    return false;
  }

  static jwt_decode(encoded) {
    const base64Url = encoded.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  static clone(obj) {
    if (this.isEmpty(obj)) return null;
    return JSON.parse(JSON.stringify(obj));
  }

  static removeArrayElement(arrs, index) {
    if (!Array.isArray(arrs) && typeof arrs !== "object") return arrs;
    if (index !== -1) {
      arrs.splice(index, 1);
    }
  }

  static generateUUID() {
    var d = new Date().getTime();
    if (
      typeof performance !== "undefined" &&
      typeof performance.now === "function"
    ) {
      d += performance.now(); //use high-precision timer if available
    }
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  static getSecret(key) {
    return POSITION.map(i => key[i]).join("");
  }

  static async getCHash(key, cnonce) {
    const encoder = new TextEncoder();
    const salt = this.getSecret(key);
    console.log("salt", salt);
    const data = encoder.encode(salt + cnonce);

    try {
      // Generate the SHA-256 hash
      const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);
      // Convert the hash to a Base64 string
      return btoa(String.fromCharCode(...new Uint8Array(hashBuffer)));
    } catch (error) {
      console.error("Error generating hash:", error);
      return null;
    }
  }

  static getEnvFromHostname() {
    const hostname = window.location.hostname;
    let env = "PROD";
    if (hostname.includes("local") || hostname.includes("LOCAL")) {
      env = "LOCAL";
    } else if (hostname.includes("sit") || hostname.includes("SIT")) {
      env = "SIT";
    } else if (hostname.includes("uat") || hostname.includes("UAT")) {
      env = "UAT";
    }
    return env;
  }

  static isCompDisabled(policy_no, comp) {
    const env_comp_map = PolicyCompDisabledConfig[this.getEnvFromHostname()];
    return _.includes(env_comp_map[policy_no], comp);
  }

  static isCompDisabledForCode(product_code, comp_name) {
    if (!product_code) return false;
    const code_comp_list = CodeCompDisabledConfig[product_code];
    return _.includes(code_comp_list, comp_name);
  }
}
export default Util;
//export const getValidateErrorCode = (error) => error.message === 'OTP00';
