import React from "react";
import { Route, Switch} from "react-router-dom";
export const renderRoutes = (routes) => {
    if (!routes) return null;
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                        // Pass nested routes to the component
                        <route.component {...props} routes={route.routes} />
                    )}
                />
            ))}
        </Switch>
    )
}