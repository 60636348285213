const getGoogleTagManagerDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
};

const handleEventLoadTime = (loadTime, pathname) => {
  const dataLayer = getGoogleTagManagerDataLayer();

  // Check if the event has already been triggered for this session
  const eventKey = `page_load_${pathname}`;
  if (sessionStorage.getItem(eventKey)) {
    return; // Prevent duplicate events
  }

  removePageLoadItemsFromSessionStorage();
  sessionStorage.setItem(eventKey, "true");

  // Push new page load event
  dataLayer.push({
    event: "page_load",
    page_load_time: parseFloat(loadTime).toFixed(2),
    page_location: pathname
  });
};

export function pageLoadTime(pathname) {
  const startTime = performance.now();

  requestAnimationFrame(() => {
    const endTime = performance.now();
    const loadTime = endTime - startTime;

    setTimeout(() => {
      handleEventLoadTime(loadTime, pathname);
    }, 2000);
  });
}

function removePageLoadItemsFromSessionStorage() {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);

    if (key && key.includes("page_load")) {
      sessionStorage.removeItem(key);
    }
  }
}
