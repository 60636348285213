export const required = (value) => value ? undefined : "This field is required";

export const validatePassportNric = (value) => {
    if (value && (value.length < 2 || value.length > 12)) {
        return "Must be between 2 and 12 characters."
    }
}

export const validateFcCode = (value) => {
    if (value && (value.length < 1 || value.length > 5)) {
        return "Must be between 1 and 5 characters."
    }
}

export const validateHeight = (value) => {
    if (value && (value < 1 || value > 999)) {
        return "Height must be between 1 and 999.";
    }
}

export const validateWeight = (value) => {
    if (value && (value < 1 || value > 999)) {
        return "Wieght must be between 1 and 999."
    }
}

export const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    if (emailRegex.test(value)) return;
    return "Invalid email format";
}

export const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);