import { combineReducers } from "redux";

import * as actionTypes from "../actions/types";
import user from "./user";
import { app } from "./app.reducer";

import { hdfReducer } from "../redux/health-declaration/hdf.reducer";

const appReducer = combineReducers({
  user,
  app,
  hdf: hdfReducer
});

const rootReducer = (state, action) => {
  if (
    action.type === actionTypes.USER_LOGOUT ||
    action.type === actionTypes.APP_RESET
  ) {
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
